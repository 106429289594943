<template>
  <div class="page1">
    <EForm
      :formColumns="formColumns"
      :rowSize="4"
      :optionsBtn="true"
      :actionBtn="false"
      :formData="searchForm"
      ref="form"
      @onSearch="onSearch"
      :searchFlag="true"
      :exportShow="false"
    >
    </EForm>
<!--    <el-button type="primary" @click="dialogVisible = true" size="small">增加</el-button>-->
<!--     <EButton type="primary" @click="dialogVisible = true">增加</EButton>-->
    <ETable
      :tableTitle="tableTitle"
      :tableData="tableData"
      :needPagination="true"
      :count="count"
      @changePage="changePage"
      @changeSize="changeSize"
      :page="searchForm.current"
      :page_size="searchForm.size"
    >
      <div slot="registProvince" slot-scope="scope">
        {{scope.data.registProvince+scope.data.registCity+scope.data.registArea}}
      </div>
      <div slot="curentProvince" slot-scope="scope">
        {{scope.data.curentProvince+scope.data.curentCity+scope.data.curentArea}}
      </div>
    </ETable>
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import { identity_type, status } from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';
  import { otherMixin } from '@/components/mixin';
  import {mapGetters} from 'vuex'
  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '姓名',
            prop: 'name',
          },
          {
            label: '手机',
            prop: 'mobile',
          },
          {
            label: '身份证号',
            prop: 'cardNo',
          },
          {
            label: '性别',
            prop: 'gender',
          },
          {
            label: '年龄',
            prop: 'age',
          },
          {
            label: '人员身份',
            prop: 'businessType',
          },
          {
            label: '居住地',
            prop: 'curentProvince',
            type: 'slot'
          },
          {
            label: '详细地址',
            prop: 'currentPlace',
          },
          {
            label: '籍贯',
            prop: 'registProvince',
            type: 'slot'
          },
          {
            label: '注册时间',
            prop: 'createTime',
          },
        ],
        formColumns: [
          {
            title: '姓名',
            type: 'text',
            property: 'name',
            show: true,
          },

          {
            title: '手机号  ',
            type: 'text',
            property: 'mobile',
            show: true,
          },
          {
            title: '身份证号',
            type: 'text',
            property: 'cardNo',
            show: true,
          },
          {
            title: '人员身份',
            type: 'select',
            property: 'businessType',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '供应商',
                value: '供应商',
              },
              {
                label: '采购商',
                value: '采购商',
              },
              {
                label: '摆渡车',
                value: '摆渡车',
              },
              {
                label: '司机/伙计',
                value: '司机/伙计',
              },
              {
                label: '中央市场办公人员',
                value: '中央市场办公人员',
              },
            ],
          },

          {
            title: '注册起始时间',
            type: 'datePicker',
            property: 'createTimeStart',
            show: true,
          },
          {
            title: '注册截止时间',
            type: 'datePicker',
            property: 'createTimeEnd',
            show: true,
          },
          {
            title: '性别',
            type: 'select',
            property: 'gender',
            show: true,
            options: [{
              label: '全部',
              value: '',
            },
              {
                label: '男',
                value: '男',
              },
              {
                label: '女',
                value: '女',
              },],
          }
        ],
        tableData: [],
        searchForm: {
          name: null,
          mobile: null,
          cardNo: null,
          businessType: null,
          createTimeStart: null,
          createTimeEnd: null,
          gender: null,
          offset: 1,
          limit: 10,
        },
        count: null,
        exportData: {},

      };
    },
    watch: {},
    created() {
      this.getData();

    },
    components: { ETable, EDialog, EButton, EForm },
    computed:{
      ...mapGetters(['buttonList']),
    },
    beforeMount() {},
    methods: {


      onSearch() {
        this.searchForm.offset = 1;
        this.getData();
      },
      changePage(current) {
        this.searchForm.offset = current;
        this.getData();
      },
      changeSize(size) {
        this.searchForm.limit = size;
        this.getData();
      },

      async getData() {
        let res = await Http.accountList(this.searchForm);
        if (res.code == 200) {
          this.tableData = res.data.rows;
          this.count = res.data.total;
        }
      },
    }
  };
</script>

<style lang="scss" scoped>
.ws {
  width: 100%;
}
</style>
